.fg-dashed-border {
  border: 2px dashed transparent !important;
  &.hover {
    border-color: #bacefd !important;
  }
  &.active {
    border-color: #1890ff !important;
    border-style: solid !important;
  }
}
.common-area {
  background-color: #fff;
  margin-bottom: 16px;
  padding: 12px 24px;
  position: relative;
}

.add-box {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.submit-box {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05),
    0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}

.fg-field-item {
  position: relative;
  margin-bottom: 0;
}
.fg-field-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fg-field-toolbar {
  position: absolute;
  right: -2px;
  top: -24px;
  background-color: #1890ff;
  padding: 0 5px;
}

