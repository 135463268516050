.form-generator {
  .ant-drawer-header {
    padding: 10px 15px;
  }
  .ant-drawer-body {
    position: relative;
    padding: 0 !important;
  }
  .form-generator-body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
  }
  .form-generator-body-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.fg-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 500;
  padding: 2px;
  background-color: #1890ff;
}
.toolbars-box {
  width: 150px;
}
.toolbars-item {
  margin-bottom: 5px;
  padding: 2px 3px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
    border-radius: 4px;
  }
}

.fg-loading {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.fg-collapse {
  .ant-collapse-header {
    padding: 8px !important;
    border-bottom: 1px solid #f2f2f2;
  }

  // 编辑区域高亮显示
  .ant-collapse-item {
    border: 2px solid transparent !important;
  }
  .edit-item {
    border: 2px solid #1890ff !important;
  }
}

