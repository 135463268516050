.fg-content {
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-content: space-between;
  height: calc(100% - 53px);
  .ant-pro-card-header {
    padding: 8px;
  }
  .ant-pro-card-body {
    padding: 0;
  }
  .fg-body {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    // padding: 24px;
    overflow: hidden;
    background-color: #eee;
    .ant-pro-card-body {
      overflow: hidden;
    }
  }
}
.fg-config-area {
  flex: 0 0 400px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  border-left: 1px solid #eee;
  // scrollbar-width: none;
}

