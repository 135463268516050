@import '~antd/dist/antd.less';

.ant-layout-sider a {
  color: #fff;
}

.app {
  height: 100vh;
}

.h-full {
  height: 100%;
}
.ant-pro-table-alert-info-option {
  display: none !important;
}
.ant-pro-basicLayout {
  min-height: 100vh;
}

.ant-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #333 !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

