.Rows {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-top: 10px;
  .RowItem {
    display: flex;
    flex-wrap: nowrap;
    align-items: center !important;
    justify-content: flex-start;
    box-sizing: border-box;
    width: calc(100% - 8px) !important;
    margin-bottom: 15px;
    margin-left: 5px !important;
    padding-top: 3px !important;
    &:hover {
      background-color: none;
    }
    &.RowItemTip {
      border: 1px solid #ff4d4f;
      border-radius: 3px;
    }
  }
  .operaBox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // margin-left: 10px;
    min-width: 140px;
    height: 32px;
  }
  .hidden {
    visibility: hidden;
  }
  .delRow {
    margin-right: 0;
  }
  .visible {
    visibility: visible;
    &.hide {
      visibility: hidden;
    }
  }
  .rowWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background-color: none !important;
    }
  }
}

.space {
  width: 15px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.rowWrapper .ant-tree-switcher {
  line-height: 40px !important;
}

.sortItem {
  padding-left: 10px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

ul {
  margin: 0;
  padding: 0 0 0 10px;
}
.sortBox {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}
.boxBorder {
  padding: 10px 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.headerBox {
  position: relative;
  top: 2px;
  flex-wrap: nowrap !important;
}
.cbox {
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}
.rowInput {
  &.error {
    border-color: #ff4d4f !important;
  }
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: transparent !important;
}

.fieldTypeBox {
  position: relative;
  .foldExpaned {
    position: absolute;
    top: -6px;
    left: -20px;
  }
}

