.fg-dashed-border {
  border: 2px dashed transparent !important;
  &.hover {
    border-color: #bacefd !important;
  }
  &.active {
    border-color: #1890ff !important;
    border-style: solid !important;
  }
}
.common-area {
  background-color: #fff;
  margin-bottom: 16px;
  padding: 12px 24px;
  position: relative;
}

.view-box {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.fg-field-item {
  position: relative;
  margin-bottom: 0;
}
.fg-field-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fg-field-toolbar {
  position: absolute;
  right: -2px;
  top: -24px;
  background-color: #1890ff;
  padding: 0 5px;
}

.fg-view-item {
  margin-bottom: 0;
  padding: 14px 0;
  cursor: pointer;
  border-bottom: 1px solid #f2f2f2 !important;
}

.fg-view-item-label {
  margin-right: 20px;
  text-align: right;
}

