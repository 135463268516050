.fg-dashed-border {
  border: 2px dashed transparent;
  &.hover {
    border-color: #bacefd;
  }
  &.active {
    border-color: #1890ff;
    border-style: solid;
  }
}
.common-area {
  background-color: #fff;
  margin-bottom: 16px;
  padding: 12px 24px;
  position: relative;
}
.fg-list-box {
  background-color: transparent !important;
  overflow-y: scroll;
}

