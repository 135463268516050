.buttonWrap {
  margin-bottom: 12px;
}
.list {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 20px;
  }
}

