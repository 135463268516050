.preview-box {
  border: 2px solid #1890ff;
  position: relative;
}
.preview-toolbar {
  font-size: 10px;
  position: absolute;
  left: -2px;
  top: -16px;
  background-color: #1890ff;
  color: #fff;
  padding: 0 5px;
  cursor: pointer;
}

