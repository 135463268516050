.fg-list-table {
  .ant-table-cell {
    position: relative;
  }
}

.fg-table-header-cell {
  position: relative;
  border: 2px dashed transparent;
}

