.card-wraper {
  margin-top: 10px;
  //   padding: 0 10px;
}
.del-wraper {
  margin-top: -10px;
  margin-bottom: 5px;
  text-align: right;
}
.padding0 {
  padding: 0;
}

