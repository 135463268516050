.pickcmps-checkcard {
  display: inline-block;
  width: auto;
  .ant-pro-checkcard-content {
    padding: 6px 10px !important;
  }
  .ant-pro-checkcard-title {
    font-weight: normal;
    font-size: 12px;
  }
}

