.fg-header {
  flex: 0 0 46px;
  box-sizing: border-box;
  padding: 10px 15px;
  border-bottom: 1px solid #f0f0f0;

  .select-option {
    display: flex;
    justify-content: space-between;
  }
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0 !important;
}

